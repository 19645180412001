// =====================================================
// Mobile menu toggle
// =====================================================
(function toggleMenu() {
  const trigger = document.querySelector('#menu-trigger');
  const body = document.querySelector('body');
  trigger.addEventListener('click', openMenu);

  function openMenu() {
    trigger.removeEventListener('click', openMenu);
    body.classList.add('menu-open');
    trigger.addEventListener('click', closeMenu);
    // window.addEventListener('resize', closeMenu);
  }
  function closeMenu() {
    trigger.removeEventListener('click', closeMenu);
    body.classList.remove('menu-open');
    trigger.addEventListener('click', openMenu);
    // window.removeEventListener('resize', closeMenu);
  }
}());
