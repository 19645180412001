// =====================================================
// Faking page load
// =====================================================
(function loadingPage() {
  // set padding top based on header
  function mainPaddingTop() {
    const headerHeight = document.querySelector('header').clientHeight;
    const main = document.querySelector('main.main');
    main.style.paddingTop = `${headerHeight}px`;
  }
  window.addEventListener('load', mainPaddingTop);
  window.addEventListener('resize', mainPaddingTop);

  // page loading
  function init() {
    document.body.classList.remove('loading');
  }
  setTimeout(init, 4000);

  window.onload = function () {
    document.body.classList.remove('loading');
  };
}());
