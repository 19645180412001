// =====================================================
// Changing the header class based on scroll
// =====================================================
(function headerClass() {
  const header = document.querySelector('header.header');

  if (window.scrollY > 50) {
    header.classList.add('small');
  } else {
    header.classList.remove('small');
  }

  window.addEventListener('scroll', headerClass);
}());
